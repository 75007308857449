import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import newtab from 'utils/newtab'

const meta = {
  title: 'E-App Tutorial Videos'
}

const videos = [
  { title: 'eSignatures – Remote Sign', url: 'https://vimeopro.com/user47344059/firelight-training/video/263943487' },
  { title: 'Navigation Bar', url: 'https://vimeopro.com/user47344059/firelight-training/video/260501557' },
  { title: 'Completing An Application', url: 'https://vimeopro.com/user47344059/firelight-training/video/252203149' },
  { title: 'Locating Application Submission Errors', url: 'https://vimeopro.com/user47344059/firelight-training/video/301258159' },
]

const EAppTutorialVideosPg = () => (
  <DefaultLayout>
    <section className="container padding richtext">
      <h1 className="h1">{meta.title}</h1>

      <ul className="richtext">
        {videos.map((video, key) => (
          <li key={key}>
            <h2 className="h3">{video.title}</h2>
            <div>
              <a className="link" href={video.url} {...newtab}>
                {video.url}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </section>
  </DefaultLayout>
)

export default EAppTutorialVideosPg
